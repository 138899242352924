import inquiryMessage from './inquiries-list/inquiry-message-resolver';
import reassignInquiry from './inquiries-list/reassign-inquiry-resolver';
import completeInquiry from './inquiries-list/complete-inquiry-resolver';
import updateInquiryInList from './inquiries-list/update-inquiry-in-list-resolver';
import blockInquiry from './inquiries-list/block-inquiry-resolver';
import transferInquiry from './inquiries-list/transfer-inquiry-resolver';
import deleteInquiry from './inquiries-list/delete-inquiry-resolver';
import removeInquiryFromCategoryList from './inquiries-list/remove-inquiry-from-category-list-resolver';

import dialogueMessage from './inquiry/dialogue-message-resolver';
import replaceOptimisticMessage from './inquiry/replace-optimistic-resolver';
import updateReplyStatuses from './inquiry/reply-statuses-resolver';
import updateCustomer from './inquiry/update-customer-resolver';
import dialogueEvent from './inquiry/dialogue-event-resolver';
import appendMessage from './inquiry/agent-appending-message-resolver';
import updateDraftMessages from './inquiry/draft-messages-resolver';
import customerTexting from './inquiry/customer-texting-resolver';
import updateInquiry from './inquiry/update-inquiry-resolver';
import updateConversationSummary from './inquiry/update-conversation-summary-resolver';
import updateInquiryFeedback from './inquiry/update-inquiry-feedback-resolver';
import updateButtonsBarHint from './inquiry/update-buttons-bar-hint-resolver';
import updateMessageVehicle from './inquiry/update-message-vehicle-resolver';
import updateVOIByInquiryId from './inquiry/update-voi-resolver';

import updateFilter from './inquiries-filter/inquiries-filter-resolver';

import addBackgroundInquiry from './inquiries-background/add-background-inquiry-resolver';
import removeBackgroundInquiry from './inquiries-background/remove-background-inquiry-resolver';
import clearBackgroundInquiries from './inquiries-background/clear-background-inquiries-resolver';

import updateActiveCategoryId from './inquiries-categories/update-inquiries-active-category-resolver';

import updateLastCheckedMessage from './inventory-search/last-checked-message-resolver';
import updateActiveInventory from './inventory-search/active-inventory-resolver';
import addInventoryIdentifiers from './inventory-search/inventory-identifiers-resolver';
import updateInventoryImagesShowStatus from './inventory-search/is-inventory-images-show-resolver';
import updateInventorySearchClosedStatus from './inventory-search/is-inventory-search-closed-resolver';

import updateCustomerLookupClosedStatus from './customer-lookup/is-customer-lookup-closed-resolver';

import updateUser from './user/update-user-resolver';

export default {
  // inquiries
  inquiryMessage,
  reassignInquiry,
  completeInquiry,
  updateInquiryInList,
  blockInquiry,
  transferInquiry,
  deleteInquiry,
  removeInquiryFromCategoryList,
  // inquiry
  dialogueMessage,
  replaceOptimisticMessage,
  updateReplyStatuses,
  updateCustomer,
  dialogueEvent,
  appendMessage,
  customerTexting,
  updateInquiry,
  updateConversationSummary,
  updateInquiryFeedback,
  updateButtonsBarHint,
  updateMessageVehicle,
  updateVOIByInquiryId,
  updateDraftMessages,
  // background inquiries
  addBackgroundInquiry,
  removeBackgroundInquiry,
  clearBackgroundInquiries,
  // inquiries-categories
  updateActiveCategoryId,
  // inventory-search
  updateLastCheckedMessage,
  updateActiveInventory,
  addInventoryIdentifiers,
  updateInventoryImagesShowStatus,
  updateInventorySearchClosedStatus,
  // customer lookup
  updateCustomerLookupClosedStatus,
  // inquiries-filter
  updateFilter,
  // user
  updateUser,
};
