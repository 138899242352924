import { gql } from '@apollo/client';
import { INQUIRY_BUTTONS_BAR_FRAGMENT } from 'core/queries/buttons-bar';

export const INQUIRY_MSG_STATS_FRAGMENT = gql`
  fragment InquiryMsgStats on MessageStatisticDto {
    lastMsgText
    lastMsgTimeStamp
    idleCustomerTimeStamp
    timerPeriod
    timerType
    unread
    unanswered
  }
`;

export const INQUIRY_CUSTOMER_FRAGMENT = gql`
  fragment InquiryCustomer on CustomerDto {
    id
    name
    firstName
    lastName
    email
    contactPhoneNumber
    phoneNumber
    status
    visitorId
    chatterSessionId
    photoLink
  }
`;

export const INQUIRY_MESSAGE_FRAGMENT = gql`
  fragment InquiryMessage on DialogueItemDto {
    id
    createdAt
    readAt
    failedAt
    failureReason
    type
    subtype
    sourceLabel
    body
    senderName
  }
`;

export const INQUIRY_DIALOGUE_QUERY_FRAGMENT = gql`
  fragment InquiryDialogItem on DialogueItemDto {
    ...InquiryMessage
    usedVehicleTMVCard {
      condition
      imageLink
      make
      mileage
      model
      price
      priceHigh
      priceLow
      priceSelected
      offerType
      trim
      year
    }
    attachments {
      id
      fileName
      niceName
      type
      url
    }
    linkPreviews {
      id
      screenshotUrl
      source
      title
      url
    }
    inventoryAttachmentCards {
      vin
      stock
      make
      model
      type
      year
      imageURL
      displayPrice
      exteriorColorR
      exteriorColorG
      exteriorColorB
      interiorColorR
      interiorColorG
      interiorColorB
    }
    workingHoursCard {
      department
      operations {
        fridayHours
        mondayHours
        saturdayHours
        sundayHours
        thursdayHours
        tuesdayHours
        wednesdayHours
      }
    }
    mediaCards {
      cardData {
        cardType
        data
      }
      id
    }
    vehicle {
      make
      model
      year
      vin
      stockNo
      status
      outdated
      dealerVdp
      edmundsVdpUrl
      msrp
      guaranteedPrice
      edmundsVehicleDealer {
        edmundsRooftopId
        id
        name
        city
        state
        street
        zip
        usedPhoneNumber
        websiteUrl
        sales {
          mondayHours
          tuesdayHours
          wednesdayHours
          thursdayHours
          fridayHours
          saturdayHours
          sundayHours
        }
      }
    }
    vehicleDealer {
      id
      name
      dealerInfo {
        allowManageServiceAppointments
        canScheduleSalesAppointment
      }
      sales {
        mondayHours
        tuesdayHours
        wednesdayHours
        thursdayHours
        fridayHours
        saturdayHours
        sundayHours
      }
      service {
        mondayHours
        tuesdayHours
        wednesdayHours
        thursdayHours
        fridayHours
        saturdayHours
        sundayHours
      }
    }
  }
  ${INQUIRY_MESSAGE_FRAGMENT}
`;

const PENDING_HISTORY_FRAGMENT = gql`
  fragment PendingHistory on PendingTransferHistoryDto {
    actionHint
    crmSent
    dealerId
    department
    notes
    scheduledAppointmentTime
    selected
    vin
  }
`;

export const INQUIRY_CONVERSATION_SUMMARY_FRAGMENT = gql`
  fragment InquiryConversationSummary on ConversationSummaryDto {
    messagesCount
    repliesCount
    conversationStartedAt
    conversationEndedAt
    avgResponseTime
    managedByUsers {
      id
      name
      userType
    }
  }
`;

export const INQUIRY_DIALOGUE_QUERY = gql`
  query GetDialoguesByInquiryId($inquiryId: Long!, $dealerId: Long!) {
    getDialoguesByInquiryId(inquiryId: $inquiryId, dealerId: $dealerId) {
      dialog {
        date
        dialogues {
          ...InquiryDialogItem
        }
      }
      events {
        id
        createdAt
        details
        type
      }
      transferHistory {
        dealerFrom {
          name
        }
        dealerTo {
          name
        }
        transferType
        transferDate
        actionHint
        scheduledAppointmentTime
        department
        notes
      }
      conversationSummary {
        ...InquiryConversationSummary
      }
    }
  }
  ${INQUIRY_DIALOGUE_QUERY_FRAGMENT}
  ${INQUIRY_CONVERSATION_SUMMARY_FRAGMENT}
`;

export const INQUIRY_FEEDBACK_FRAGMENT = gql`
  fragment InquiryFeedbackFragment on InquiryFeedbackDto {
    starRank
    comment
    createdAt
  }
`;

export const INQUIRY_FEEDBACK_QUERY = gql`
  query GetInquiryFeedback($inquiryId: Long!) {
    inquiryFeedback(inquiryId: $inquiryId) {
      ...InquiryFeedbackFragment
    }
  }
  ${INQUIRY_FEEDBACK_FRAGMENT}
`;

export const INQUIRY_QUERY = gql`
  query Inquiry($inquiryId: Long!) {
    inquiry(id: $inquiryId) {
      id
      archiveId
      dealerId
      ended
      stopped
      isBlocked
      conversationCompleted
      isNew
      isMmsEnabled
      isHotLead
      conversationType
      source
      department
      type
      archivedAt
      proxyUserId
      proxyDealerId
      isIntercepted
      crmNotificationSent
      dealer {
        id
        name
      }
      phoneNumber {
        dealerId
        dealerName
        label
        phoneNumber
      }
      assigned {
        id
        name
      }
      tradeInVehicleDto {
        inquiryId
        make
        model
        year
        mileage
        zip
        condition
        vin
      }
      customer {
        ...InquiryCustomer
      }
      originDealer {
        id
        name
        partnerProgram
        phoneNumber
        servicePhoneNumber
        isTccEnabled
        ...InquiryButtonsBarProperties
      }
      msgStats {
        ...InquiryMsgStats
      }
      changelogs {
        id
        action
        application
        createdAt
        inquiryId
        requestorUserId
        requestorUserName
      }
      inquiryArchivedHistory {
        archivedInquiryId
        archivedInquiryPrevId
        firstMessage
        lastMessage
        messageCount
      }
    }
  }
  ${INQUIRY_CUSTOMER_FRAGMENT}
  ${INQUIRY_BUTTONS_BAR_FRAGMENT}
  ${INQUIRY_MSG_STATS_FRAGMENT}
`;

export const CUSTOMER_TEXTING_QUERY = gql`
  query CustomerTexting($id: Long!) {
    customerTexting(id: $id) @client {
      body
    }
  }
`;

export const DRAFT_MESSAGES_CLIENT_QUERY = gql`
  query DraftMessages {
    draftMessages @client {
      userId
      inquiryId
      message
    }
  }
`;

export const PENDING_HISTORIES_LIST_QUERY = gql`
  query GetPendingHistoriesList($input: PendingSearchCriteriaInput!) {
    getPendingHistoriesList(input: $input) {
      ...PendingHistory
    }
  }
  ${PENDING_HISTORY_FRAGMENT}
`;

export const PENDING_HISTORY_QUERY = gql`
  query GetPendingHistory($input: PendingSearchCriteriaInput!) {
    getPendingHistory(input: $input) {
      ...PendingHistory
    }
  }
  ${PENDING_HISTORY_FRAGMENT}
`;

export const APPENDED_MESSAGE_CLIENT_QUERY = gql`
  query AppendMessage($inquiryId: Long!) {
    appendMessage(inquiryId: $inquiryId) @client {
      appendedMessage
    }
  }
`;

export const DEALERS_FOR_TRANSFER_QUERY = gql`
  query DealersForTransfer($dealerId: Long!) {
    dealersForTransfer(dealerId: $dealerId) {
      dealersForTransfer {
        dealerId
        dealerName
        phoneNumbers {
          id
          label
          number
        }
      }
    }
  }
`;
