import PropTypes from 'prop-types';

import { CustomerPropTypes } from 'core/prop-types';

import MessageStatistics from './message-statistics';

export default PropTypes.shape({
  id: PropTypes.number,
  source: PropTypes.string,
  customer: CustomerPropTypes.Customer,
  type: PropTypes.string,
  msgStats: MessageStatistics,
  isTccEnabled: PropTypes.bool,
});
