export const EDW_TRACKING_URL = '//edw.edmunds.com/edw/edw1x1.gif';

export const ACTIONS = {
  SUCCESS: 'success',
  CLICK: 'click',
  SHORTCUT: 'shortcut_applied',
  SHOW_DIALOG: 'show_dialog',
  INQUIRY: 'inquiry',
  OPEN_FILTER: 'open_filter',
  SORT_BY_TIME: 'sort_by_time',
  DARK_MODE_TOGGLE: 'dark_mode_toggle',
};

export const CATEGORIES = {
  INQUIRY: 'inquiry',
  INQUIRIES: 'inquiries',
  INVITE_CUSTOMER: 'invite_customer',
  PWA: 'pwa',
  NOTIFICATION: 'notification',
  UI_APPEARANCE: 'ui_appearance',
};

export const LABELS = {
  ADD_IMAGE: 'add_image',
  ADD_ADDRESS: 'add_address',
  ADD_HOURS: 'add_hours',
  ADD_CREDIT_APPLICATION: 'add_credit_application',
  ADD_SERVICE_APPLICATION: 'add_service_application',
  ADD_VYT: 'add_vyt',
  ADD_TO_ASSIGN: 'add_to_assign',
  ADD_TO_TRANSFER: 'add_to_transfer',
  CUSTOMER_SERVICE_HISTORY: 'customer_service_history',
  DEALER_REVIEW: 'dealer_review',
  TEST_DRIVE: 'test_drive',
  INVENTORY_SEARCH: 'inventory_search',
  TRADE_IN_APPRAISAL: 'trade_in_appraisal',
  ADD_HOURS_OF_OPERATION: 'add_hours_of_operation',
  ADD_TMV_REPORT: 'add_tmv_report',
  IM_DEAL_PHONE: 'im_deal_phone',
  IM_DEAL_WEBSITE: 'im_deal_website',
  IM_DET_HISTORY: 'im_det_history',
  IM_DET_DISPLAY_PRICE: 'im_det_display_price',
  IM_DET_MILEAGE: 'im_det_mileage',
  IM_DET_COLOR: 'im_det_color',
  IM_DET_MPG: 'im_det_mpg',
  IM_DET_SEATING: 'im_det_seating',
  IM_DET_MEDIA: 'im_det_media',
  IM_DEAL_PREQUAL_FORM: 'im_deal_prequal_form',
  IM_APPRAISAL_FORM: 'im_appraisal_form',
  SHOW_COMPLETED: 'show_completed',
  SHOW_ALL: 'show_all',
  SHOW_ARCHIVED: 'show_archived',
  SHOW_ASSIGNED: 'show_assigned',
  SHOW_ASSIGNED_TO_ME: 'show_assigned_to_me',
  SHOW_MANAGED: 'show_managed',
  SHOW_OUTBOUND: 'show_outbound',
  SHOW_UNASSIGNED: 'show_unassigned',
  BLOCK_CONVERSATION: 'block_conversation',
  ADD_TO_COMPLETE: 'add_to_complete',
  RESUME_CONVERSATION: 'resume_conversation',
  VIEW_INQUIRY: 'view_inquiry',
  INVITE_CUSTOMER: 'invite_customer',
  INVITE: 'invite',
  FILTER: 'filter',
  TIME: 'time',
  CLIENT_WEBSOCKET_CONFIRM: 'carcode_client_websocket_event',
  CLIENT_CTA_EVENT: 'carcode_client_cta_event',
  END_CHAT: 'end_chat',
  UPDATE_CRM: 'update_CRM',
  ADD_TO_UNARCHIVE: 'add_to_unarchive',
  LIGHT_MODE_ENABLED: 'light_mode_enabled',
  DARK_MODE_ENABLED: 'dark_mode_enabled',
};
