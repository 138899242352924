import decorator from 'core/resolvers/graphql-type-decorator';
import { IS_CUSTOMER_LOOKUP_CLOSED_CLIENT_QUERY } from 'core/queries/customer-lookup';

export default (obj, { input: { inquiryId, isClosed } }, { cache, getCacheKey }) => {
  cache.writeQuery({
    query: IS_CUSTOMER_LOOKUP_CLOSED_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      isCustomerLookupClosed: decorator.isCustomerLookupClosed({
        isClosed,
      }),
    },
  });

  return null;
};
